import React from 'react';
import DownloadFree from '../containers/sections/DownloadFree/DownloadFree';
import { css } from 'aphrodite';
import STYLES from '../styles/shared';
import Layout from '../containers/gatsby/layout';
import { FONTS } from '../styles/fonts';
import COLORS from '../styles/colors';

const TermsOfUsePage = (props) => (
        <Layout location={props.location}>
                <div className={css(STYLES.documentContent)}>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        An additional fee 25£+TAX is charged for any changes or updates of information, changing the date of transfer or the time slot of booking.
                                        Your call may be recorded for training or quality control purposes.
                                </span>
                        </p>
                        <h1 style={{ fontFamily: FONTS.Content }}><span>TERMS &amp; CONDITIONS</span></h1>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        By accepting our price estimate and booking our man and van services YOU ARE AGREEING TO OUR TERMS &amp;
                                        CONDITIONS. Therefore you are obligated to follow and respect our TERMS &amp; CONDITIONS, part of which
                                        are posted below, unless you EXPLICITLY express otherwise by contacting us upon receiving our TERMS &amp;
                                        CONDITIONS.
                                </span>
                        </p>
                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>DATE AND TIME- WEEK DAY, DATE, ARRIVAL TIME</span></h3>
                        <br />
                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>NAME AND CONTRACTS NUMBER- CUSTOMER NAME, CONTRACT NUMBER</span></h3>
                        <br />
                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>RELOCATING FROM- STREET, TOWN, POST CODE</span></h3>
                        <br />
                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>RELOCATING TO- STREET, TOWN, POST CODE</span></h3>
                        <br />
                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>ITEMS OF REMOVAL- LIST OF ITEMS FOR REMOVAL</span></h3>
                        <br />
                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>NUMBER OF DRIVER/LOADER</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        The number of vans and drivers that will be sent to you is determined by the volume and quantity of items
                                        that you have declared for removal, unless you EXPLICITLY don’t request a particular number of vans and drivers.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PRICE ESTIMATE</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        The estimated one hour rate is PRICE PER HOUR for one driver and one van. The MINIMAL amount of time for
                                        a service is TWO HOURS. The time is summed upon the completion of the project with the service time being
                                        ROUNDED UP TO THE CLOSEST HALF HOUR. Charge time begins UPON THE ARRIVAL OF THE FIRST DRIVER AND VAN.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PRICING PER HOUR AND PAYMENT</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        The estimate rate is PRICE PER TWO HOURS for (number of men) for up to (amount of time). The estimated price is based on
                                        the information that YOU have provided us and the estimate of time that it will take us to complete the job. The
                                        additional time charge rate is PRICE PER HOUR per hour.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PAYMENT METHOD</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        The accepted payment methods are CASH and BANK TRANSFER(+fee) payments.
                                </span><span>&nbsp;</span>
                        </p>
                        <br />
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        We do not store credit card details nor do we share customer details with any 3rd
                                        parties.
                                </span><span>&nbsp;</span>
                        </p>
                        <br />

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PREPARING FOR RELOCATION</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        The preparation for the removal is YOUR responsibility, unless you have EXPLICITLY
                                        requested and paid for packing services. PLEASE NOTE: The premises must be properly
                                        prepared.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PREPARATIONS</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        The preparation for the removal includes (but is not limited to) packing all items
                                        declared for removal and PROPERLY SEALING AND SECURING all boxes, bags, suitcases
                                        and etc that will be moved. Granting EASY ACCESS to our team of driver/loader to
                                        the items that are going to be moved. ALL ITEMS which weren’t initially declared
                                        and are going to be moved are SUBJECT TO ADDITIONAL CHARGES which will be in
                                        accordance to our hourly rates.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PREPAYMENT</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        Prepayment is required. In such cases a PART or the ENTIRE QUOTED PRICE is to be
                                        given to the driver before the commencement of the relocation. Any additional
                                        charges due to prolonged time or undeclared items are paid upon the completion of
                                        the project.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PAYMENT – OBLIGATIONS</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        Payment must be paid as soon as service was provided. In case of refusing payment
                                        we can store your items until payment has been received. We can use a statutory
                                        demand to formally request payment of outstanding balance and charge interest for
                                        late payment.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>COMPLAINTS &amp; PROBLEMS</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        Problems with the condition of the moved items should be reported before the
                                        commencement of the removal. Any complaints about the condition of the items
                                        after the removal should be reported before the completion of the project.
                                        Any DAMAGES (scratches, breakages, holes, and so on) and LOSES on both the
                                        premises and moved items, must be taken to the attention of our team of
                                        driver/loader before the completion of the project. Once you make the final
                                        payment it is considered that you are AGREEING with the manner by which the
                                        project was handled and therefore you DON’T HAVE any complaints.
                                </span><span>&nbsp;</span>
                        </p>

                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        Our man and van company (JJD Removals) does not oblige itself with completing
                                        a project within any timetable or before a specific moment in time. Our
                                        company will NOT BE HELD liable for delays caused by unforeseen events and
                                        reasons independent of us (weather conditions, closed roads, road traffic,
                                        difficult access to the premises of the removal or delivery and etc.). We are
                                        not responsible for any missed deadlines, as all removals and services are
                                        executed as quickly as possible, but we DO NOT give any guarantee of completion
                                        time.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>SUPPLEMENTARY FEES</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        (including packing services) that are not mentioned in the quote are NOT included
                                        in the QUOTE PRICE that is due by our client. Such fees include (but are not
                                        limited to) parking fees, congestion charge and any other fees that are related
                                        to the execution and completion of the project. It is YOUR obligation to COVER or
                                        REIMBURSE US for these fees before the completion of the project.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>CANCELLATION &amp; REFUND</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        In the event of service cancellation by the customer, we reserve the right to charge
                                        a £25+VAT service cancellation fee. Refunds are only issued in the event of unexpected
                                        circumstances which would make it impossible for the moving team to be on site and
                                        complete the service. We reserve the right to charge for jobs (over 100£) to keep 30%
                                        of price amount.
                                </span><span>&nbsp;</span>
                        </p>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        We reserve our right to use the logo of any company or organisation using our services
                                        in the customers section of our company website.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>PARKING</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        When it comes for parking the responsibility should be yours. If there are restrictions
                                        (red routes, yellow lines, residents only etc) a permit must be provided from the local
                                        council and so on ... If there is no parking pre-arranged any parking fines received
                                        will be the responsibility of the customer and must be paid by the customer on completion,
                                        however we will not park illegally and the driver may have to leave if legal parking is
                                        not provided.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>CONGESTION &amp; TOLL CHARGES</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        There will be an extra charge of £15 when passing through the London Congestion Charge Zone,
                                        other congestion charge zones and tolls will be charged accordingly. (Unless otherwise stated)
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>ARRIVAL / DELIVERY TIMES</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        Our arrival times are estimated and in case of a delay we will contact the customer.
                                </span><span>&nbsp;</span>
                        </p>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        We do not take responsibility for delays to pick-up/delivery times due to busy traffic,
                                        bad weather etc.
                                </span><span>&nbsp;</span>
                        </p>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        If a driver is delayed for more than 1 hour to a booking, the customer is due a partial
                                        refund for this lateness as per our standard refund policy. Please see below:
                                </span><span>&nbsp;</span>
                        </p>
                        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", }}>
                                <div>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>Up to 1 hour late</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>1 – 1.5 hours late</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>1.5 – 2 hours late</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>2 – 3 hours late</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>Next day reschedule</p>
                                </div><span>&nbsp;</span>
                                <div>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>- Your patience is appreciated</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>- 5% refund</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>- 10% refund</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>- 15% refund</p>
                                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>- 35% refund</p>

                                </div><span>&nbsp;</span>
                        </div>
                        <br />
                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>YOURS PACKAGING RESPONSIBILITIES</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        We do not take responsibility for damaged goods that have not been well packed or protected.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>STAFF ABUSE</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        Verbal or threatening behaviour will not be tolerated. If the driver is forced to leave
                                        the job because of abuse from the customer verbally or otherwise the customer will still
                                        be liable to pay in full.
                                </span><span>&nbsp;</span>
                        </p>

                        <h3 style={{ fontFamily: FONTS.Content, fontWeight: 600, }}><span>CHANGES TO THE BOOKING</span></h3>
                        <p style={{ fontFamily: FONTS.Content, color: COLORS.jjdGreyish }}>
                                <span>
                                        An additional fee 25£+TAX is charged for any changes or updates of information, changing the
                                        date of transfer or the time slot of booking.
                                </span><span>&nbsp;</span>
                        </p>

                </div>
                <DownloadFree />
        </Layout >
);

export default TermsOfUsePage;
